import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TagLink from "../components/tagLink"
import { rhythm } from "../utils/typography"

const TagListTemplate = ({ data, pageContext, location }) => {
  const { tags } = pageContext
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} style="home">
      <SEO title={siteTitle} />
      <h1>Argomenti</h1>
      <ul style={{ listStyle: 'none' }}>
      {tags.map((tag, i) => {
        return (
          <li key={i} style={{
            display: 'inline-block',
            marginRight: rhythm(.4)
          }}>
            <TagLink tag={tag}></TagLink>
          </li>
        )
      })}
      </ul>
    </Layout>
  )
}

export default TagListTemplate

export const pageQuery = graphql`
  query tagListQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;